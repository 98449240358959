import { Container, Box, Heading, BoxProps, Grid, GridItem, Flex, Text, Link } from '@chakra-ui/react'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'
import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Button, Icon, Image } from '@miimosa/design-system/components'

import { ProjectCard } from '@components/ProjectCard'

interface Props {
  projects: FrontSearchProjectType[]
  votes: { slug: string; total_vote_count: number }[]
}

const TwoProjects: FC<Props & BoxProps> = ({ votes, projects }) => {
  return (
    <Container variant="full" bgColor="#95c34d" position="relative" overflow="hidden">
      <Box
        position="absolute"
        width="80px"
        display={{ base: 'none', md: 'block' }}
        top={{ md: '200px' }}
        height="79px"
        right={{ md: '-15px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-light-branch.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Container variant="boxed" position="relative" py={undefined}>
        <Box display={{ base: 'none', md: 'block' }} position="absolute" top="0px" left="160px" zIndex="1">
          <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
        </Box>
        <Flex direction="column" w="full" rowGap={3} maxW={{ base: '300px', md: '500px', lg: '700px' }}>
          <Heading size="md" color="white" mt={10} textAlign="left">
            Cette année, nous soutenons 5 projets d’arboriculteurs français dont les objectifs sont :
          </Heading>
          <Flex direction="column" rowGap={2} textAlign="left" alignSelf={'left'} w="full">
            <Text color="white" size="lg">
              • adopter des pratiques alliant production agricole et préservation des ressources
            </Text>
            <Text color="white" size="lg">
              • prendre soin de la nature
            </Text>
            <Text color="white" size="lg">
              • devenir plus résilients face au changement climatique
            </Text>
            <Text color="white" size="lg">
              • protéger les sols et préserver la biodiversité
            </Text>
            <Text color="white" size="lg">
              • réduire la déforestation et encourager une utilisation raisonnée de l&apos;eau
            </Text>
          </Flex>
          <Flex direction="column" textAlign="left">
            <Heading size="md" color="white">
              Grâce à vous, nous pouvons les aider à prendre soin de l’environnement !
            </Heading>
          </Flex>
        </Flex>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} my={10}>
          {projects.slice(0, 8).map((p, index) => {
            const projectVote = votes.find(({ slug: pSlug }) => pSlug === p.slug)

            return (
              <GridItem position="relative" key={index}>
                {index === 1 && (
                  <Box
                    position="absolute"
                    width="61px"
                    display={{ base: 'none', md: 'block' }}
                    bottom={{ md: '40px' }}
                    height="113px"
                    right={{ md: '-50px' }}
                    zIndex="0"
                    transform="scale(-1, 1)"
                  >
                    <Image
                      alt="innocent"
                      src={s3ImageURL('innocent-dark-branch.svg')}
                      fill
                      style={{ objectFit: 'cover' }}
                    />
                  </Box>
                )}
                <ProjectCard
                  project={p}
                  zIndex="1"
                  voteCollected={projectVote?.total_vote_count}
                  isBordered
                  withOwner={true}
                  hideVideo={true}
                  hideQR
                />
              </GridItem>
            )
          })}
        </Grid>
        <Flex
          direction="column"
          rowGap={2}
          maxW={{ base: '300px', md: '500px', lg: '700px' }}
          textAlign="center"
          mb={10}
          alignItems="center"
        >
          <Heading size="md" color="white">
            Pour cette troisième édition, Elina a eu l’opportunité d’être accompagnée par l’association Pour Une
            Agriculture du Vivant, tiers de confiance de la transition agroécologique, afin de réaliser son Indice de
            Régénération des sols
          </Heading>
          <Text size="xl" color="white">
            Cet indice <strong>mesure le résultat des pratiques agricoles</strong> sur 3 niveaux agronomiques pour agir
            de manière systémique : sol, plante et paysage.
          </Text>
          <Flex direction="row" columnGap={4}>
            <Icon name="LogoPADV" size="3xl" h="auto" mt={2} />
            <Image
              src={s3ImageURL('logo-indice-regeneration-padv.png')}
              alt="logo indice regeneration"
              width="100"
              height="150"
            />
          </Flex>
        </Flex>
        <Link
          isExternal
          href="https://agricultureduvivant.org/leviers-daction/lindice-de-regeneration/"
          _hover={{ textUnderline: 'none' }}
        >
          <Button variant="secondaryW" w="fit-content" textTransform={'none'}>
            découvrir l’Indice de Régénération
          </Button>
        </Link>
      </Container>
      <Box
        position="absolute"
        width="78px"
        display={{ base: 'none', md: 'block' }}
        bottom={{ md: '250px' }}
        height="90px"
        left={{ md: '-20px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-white-flower.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
    </Container>
  )
}

export default TwoProjects
