import { Box, Container, Heading, Flex, Text, Link, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Button } from '@miimosa/design-system/components'
import { Image } from '@miimosa/design-system/components'

const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const headerIllustrationPath = useBreakpointValue(
    { base: 'innocent-illus-top-mobile.svg', md: 'header-sub-illu.svg' },
    { fallback: 'md' }
  )
  const footerIllustrationPath = useBreakpointValue(
    { base: 'illus_bas_etiquette-mobile.svg', md: 'illus_bas_etiquette.svg' },
    { fallback: 'md' }
  )

  return (
    <Container variant="full" bgColor="#B2E2F5" position="relative" overflow="hidden">
      <Box
        position="absolute"
        width="100%"
        top={{ base: '-20px', md: '-80px', '2xl': '-150px' }}
        height={{ base: '150px', md: '245px', '2xl': '425px' }}
        left="0"
      >
        <Image
          alt="innocent"
          src={s3ImageURL(headerIllustrationPath || 'header-sub-illu.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="166px" left="200px">
        <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
      </Box>
      <Container
        variant="boxed"
        pt={{ base: '30px', md: 14, '2xl': '160px' }}
        pb={{ base: '120px', md: 10, '2xl': '140px' }}
        position="relative"
      >
        <Flex direction="column" align="center" mt="8">
          <Text fontFamily="innocent-super_scrawl" color="secondary.TERTIARY" fontSize="50px" mb="2">
            REJOIGNEZ
          </Text>
          <Image
            alt="logo classe verte"
            src={s3ImageURL('logo_classe_verte.svg')}
            width={useBreakpointValue({ base: '187', md: '328' }, { fallback: 'md' })}
            height={useBreakpointValue({ base: '60', md: '105' }, { fallback: 'md' })}
          />
        </Flex>
        <Flex
          direction="column"
          textAlign="center"
          mt={{ base: '12' }}
          mb={{ base: '6', md: '110px' }}
          align="center"
          maxW={{ base: '320px', md: '608px' }}
          zIndex={10}
        >
          <Flex direction="column" w="full" rowGap={3}>
            <Heading size="sm" color="secondary.TERTIARY">
              <strong>la grande classe verte fait sa rentrée</strong>. Pour sa 3ème année consécutive, le{' '}
              <strong>partenariat</strong> entre innocent et MiiMOSA encourage une conversation sur la nature et{' '}
              <strong>soutient des projets</strong> adoptant des pratiques qui allient production agricole et
              préservation des ressources naturelles. <br></br>
              Notre mission : favoriser la <strong>revégétalisation</strong> et préserver la{' '}
              <strong>biodiversité</strong>. Et pour y arriver, on invite tous ceux qui le souhaitent (toi, plus moi par
              exemple) à donner un coup de pousse à la nature en soutenant les agriculteurs français dans leurs projets
              de <strong>transition écologique</strong>.
            </Heading>
          </Flex>
          <Link
            href="https://www.innocent.fr/ce-que-nous-faisons-pour-la-planete"
            target="_blank"
            textDecoration="none"
            _hover={{
              textDecoration: 'none',
            }}
            mt={6}
          >
            <Button variant="secondary" color="secondary.SECOND" _hover={{ color: 'secondary.TERTIARY' }}>
              ce qu’innocent fait pour l’environnement
            </Button>
          </Link>
        </Flex>
      </Container>
      <Box
        position="absolute"
        width="29px"
        top={{ base: '160px', md: '366px' }}
        height="29px"
        left={{ base: '60px', md: '100px' }}
      >
        <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      {!isMobile && (
        <Box
          position="absolute"
          width="29px"
          height="29px"
          top={{ base: '280px', md: '366px' }}
          right={{ base: '60px', md: '114px' }}
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
      )}
      <Box position="absolute" width="100%" bottom="0" height={{ base: '349px', '2xl': '590px' }} left="0">
        <Image
          alt="innocent"
          priority
          src={s3ImageURL(footerIllustrationPath || 'illus_bas_etiquette.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </Container>
  )
}

export default Hero
