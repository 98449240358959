import { Box, Flex, Text, Heading, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, Icon, FullWidthContainer, Image } from '@miimosa/design-system/components'
import { IconSize } from '@miimosa/design-system/components/Icon'

const HeroManifest = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const headerSize = useBreakpointValue({ base: 'lg', md: 'xl' }, { fallback: 'md' })
  const youpiSize = useBreakpointValue({ base: 'xs', md: 'lg' }, { fallback: 'md' }) as IconSize
  const footerIllustrationPath = useBreakpointValue(
    { base: 'illus-bottom-manifest-mobile.svg', md: 'illus-bottom-manifest.svg' },
    { fallback: 'illus-bottom-manifest.svg' }
  )

  return (
    <FullWidthContainer bgColor="bg.0" color="gray.200" position="relative" overflow="hidden">
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="11px" left="-40px">
        <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} width="88" height="90" />
      </Box>
      <Box display={{ base: 'none', md: 'block' }} position="absolute" top="60px" left="200px">
        <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
      </Box>
      {!isMobile && (
        <Box
          position="absolute"
          width="29px"
          top={{ base: '160px', md: '210px' }}
          height="29px"
          left={{ base: '60px', md: '96px' }}
          transform="rotate(-55deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
      )}
      <Box
        position="absolute"
        width="61px"
        display={{ base: 'none', md: 'block' }}
        top={{ md: '-30px' }}
        height="113px"
        right={{ md: '-20px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      {!isMobile && (
        <Box
          position="absolute"
          width="29px"
          top={{ base: '160px', md: '120px' }}
          height="29px"
          right={{ base: '60px', md: '290px' }}
          transform="rotate(-10deg)"
        >
          <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
      )}
      {!isMobile && (
        <Box
          position="absolute"
          width="50px"
          top={{ base: '160px', md: '270px' }}
          height="43px"
          right={{ base: '60px', md: '120px' }}
          transform="scale(-1, 1)"
        >
          <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="50" height="43" />
        </Box>
      )}
      <BoxedContainer
        pt={{ base: '40px', md: 20, '2xl': '160px' }}
        pb={{ base: '140px', md: 12, '2xl': '140px' }}
        position="relative"
        zIndex="1"
      >
        <Box display={{ base: 'block', md: 'none' }} position="absolute" top="80px" left="-40px">
          <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} width="88" height="90" />
        </Box>
        <Box
          position="absolute"
          width="61px"
          display={{ base: 'block', md: 'none' }}
          top={{ base: '-50px' }}
          height="113px"
          right={{ base: '-20px' }}
        >
          <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box position="absolute" top={{ base: '-15px', md: '-15px' }} left={{ base: '-20px', md: '70px' }}>
          <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} width="43" height="43" />
        </Box>
        <Flex align="center">
          <Icon name="YoupiLeft" size={youpiSize} color="#74AA50" />
          <Heading size={headerSize} color="#000" textAlign="center">
            chez innocent,
            <br /> on a deux grands rêves
          </Heading>
          <Icon name="YoupiRight" size={youpiSize} color="#74AA50" />
        </Flex>

        <Text size="md" width={{ base: '335px', md: '654px' }} mt={{ base: '50px', md: '78px' }} align="justify">
          Comme celui d’aider les gens à prendre soin d’eux. Ou celle de faire un karaoké avec Céline Dion*. L’un de nos
          plus grands rêves, c’est de prendre soin de la nature. Plus qu’un rêve, c’est une mission. Le changement
          climatique est l&apos;un des plus gros défis auxquels nous devons faire face et, collectivement, nous devons
          faire notre maximum pour le limiter.
          <br />
          <br />
          De notre côté, on s’est engagés -dans le cadre de notre objectif certifié SBTi - à réduire notre empreinte
          carbone de 50% par litre de boisson d&apos;ici à 2030. Et on aimerait aller encore plus loin avec notre
          ambition « Net Zéro » pour 2040. *si jamais vous avez son numéro appelez-nous
        </Text>
        <Text width={{ base: '335px', md: '654px' }} textAlign="right" size="md" mt="8px">
          *si jamais vous avez son numéro appelez-nous
        </Text>
      </BoxedContainer>

      <Box position="absolute" width="100%" bottom="0" height={{ base: '110px', md: '346px', '2xl': '590px' }} left="0">
        <Image
          alt="innocent"
          src={s3ImageURL(footerIllustrationPath || 'illus-bottom-manifest.svg')}
          fill
          style={{ objectFit: 'cover' }}
        />
      </Box>
    </FullWidthContainer>
  )
}

export default HeroManifest
