import { Box } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system/components'

const Ornaments = () => (
  <>
    <Box
      position="absolute"
      width="29px"
      display={{ base: 'none', md: 'block' }}
      top={{ md: '20px' }}
      height="29px"
      right={{ md: '460px' }}
      transform="rotate(-10deg)"
    >
      <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
    </Box>
    <Box display={{ base: 'none', md: 'block' }} position="absolute" top="40px" right="200px">
      <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
    </Box>
    <Box
      position="absolute"
      width="80px"
      display={{ base: 'none', md: 'block' }}
      top={{ md: '200px' }}
      height="79px"
      right={{ md: '-15px' }}
    >
      <Image alt="innocent" src={s3ImageURL('innocent-light-branch.svg')} fill style={{ objectFit: 'cover' }} />
    </Box>
    <Box
      position="absolute"
      width="29px"
      display={{ base: 'none', md: 'block' }}
      top={{ md: '340px' }}
      height="29px"
      right={{ md: '130px' }}
      transform="rotate(-50deg)"
    >
      <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
    </Box>
    <Box
      position="absolute"
      width="101px"
      display={{ base: 'none', md: 'block' }}
      top={{ md: '780px' }}
      height="64px"
      left={{ md: '-30px' }}
    >
      <Image alt="innocent" src={s3ImageURL('innocent-leave.svg')} fill style={{ objectFit: 'cover' }} />
    </Box>
    <Box
      position="absolute"
      width="29px"
      display={{ base: 'none', md: 'block' }}
      bottom={{ md: '40px' }}
      height="29px"
      left={{ md: '120px' }}
      transform="rotate(-50deg)"
    >
      <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
    </Box>
  </>
)

export default Ornaments
