import { Heading, Box, AccordionItem, Flex, AccordionButton, Accordion, AccordionPanel } from '@chakra-ui/react'
import { ReactNode, useMemo, useState } from 'react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, Icon, Image, FullWidthContainer } from '@miimosa/design-system'

import questions from './questions'

const QuestionItem = ({ title, text, isOpen }: { title: string; text: ReactNode; isOpen: boolean }) => {
  return (
    <AccordionItem id={title}>
      <AccordionButton
        borderTopLeftRadius="4px"
        borderTopRightRadius="4px"
        borderBottomLeftRadius={isOpen ? 0 : '4px'}
        borderBottomRightRadius={isOpen ? 0 : '4px'}
        _hover={{
          bg: 'white',
        }}
        _focus={{
          outline: 0,
          boxShadow: 'none',
        }}
        _expanded={{ bg: 'white', color: '#000' }}
        borderColor="red"
        bgColor="white"
        mt="8"
        py="20px"
      >
        <Flex justify="space-between" align="center" flex="1 1 0%">
          <Heading as="h3" size="md" textAlign="left" pr="4px">
            {title}
          </Heading>
          <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} color="#E87722" size="3xs" />
        </Flex>
      </AccordionButton>
      <AccordionPanel bgColor="white" pr="8" borderBottomLeftRadius="4px" borderBottomRightRadius="4px">
        {text}
      </AccordionPanel>
    </AccordionItem>
  )
}

const QuestionsList = () => {
  const [indexOpened, setIndexOpened] = useState<number>(0)
  const memoData = useMemo(() => questions(), [])

  return (
    <FullWidthContainer bgColor="#74AA50" color="#000" position="relative" overflow="hidden">
      <Box
        position="absolute"
        width="101px"
        display={{ base: 'none', md: 'block' }}
        top={{ md: '260px' }}
        height="64px"
        left={{ md: '-30px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-leave.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        width="98px"
        left={{ base: '-30px', md: '-30px' }}
        height="90px"
        bottom={{ base: '440px', md: '80px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-fougere.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        display={{ base: 'none', md: 'block' }}
        right="-34px"
        bottom={{ base: '45%', md: '420px' }}
      >
        <Image src={s3ImageURL('innocent-white-flower.svg')} alt="" width="98" height="78" />
      </Box>
      <Box
        position="absolute"
        width="43px"
        display={{ base: 'block', md: 'none' }}
        bottom={{ base: '120px' }}
        height="43px"
        right={{ base: '0px' }}
      >
        <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <Box
        position="absolute"
        display={{ base: 'block', md: 'none' }}
        width="33px"
        bottom={{ base: '960px' }}
        height="33px"
        left={{ base: '-15px' }}
        transform="rotate(10deg)"
      >
        <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
      </Box>
      <BoxedContainer position="relative" px={undefined}>
        <Accordion
          onChange={(index) => setIndexOpened(index as number)}
          w={{ base: '345px', md: '927px' }}
          allowToggle
          borderColor="transparent"
          defaultIndex={0}
          zIndex="1"
        >
          {memoData.map(({ title, text }, i) => {
            const isOpen = i === indexOpened
            return <QuestionItem key={`${title}_${i}`} isOpen={isOpen} title={title} text={text} />
          })}
        </Accordion>
        <Box
          position="absolute"
          width="33px"
          top={{ base: '350px', md: '280px' }}
          height="33px"
          right={{ base: '30px', md: '40px' }}
          transform="rotate(120deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box position="absolute" display={{ base: 'block', md: 'none' }} right="-20px" top="680px" zIndex="0">
          <Image src={s3ImageURL('innocent-dark-branch.svg')} alt="" width="61" height="113" />
        </Box>
        <Box
          display={{ base: 'none', md: 'block' }}
          position="absolute"
          width="29px"
          top={{ base: '160px', md: '800px' }}
          height="29px"
          right={{ base: '60px', md: '-80px' }}
          transform="rotate(10deg)"
        >
          <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
        <Box
          position="absolute"
          width="29px"
          top={{ base: '160px', md: '1000px' }}
          height="29px"
          left={{ base: '60px', md: '-40px' }}
          transform="rotate(120deg)"
        >
          <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>
      </BoxedContainer>
    </FullWidthContainer>
  )
}

export default QuestionsList
