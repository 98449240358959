import { Flex, Box } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { BoxedContainer, Image, FullWidthContainer } from '@miimosa/design-system/components'

import CommitmentBlock from './CommitmentBlock'
import Ornaments from './Ornaments'

const commitmentsData = [
  {
    align: 'flex-start',
    isLight: true,
    bgColor: '#74AA50',
    title: 'faire les choses bien',
    svgPath: 'apple',
    text: [
      'chez innocent, on a toujours eu l’ambition de devenir bons dans tous les domaines, ce qui veut dire faire du profit, bien sûr, mais le faire d’une bonne façon. C’est pour cette raison que nous sommes devenus une entreprise certifiée B Corp en 2018 avec un score de 92,5 points et que nous avons redoublé d’efforts 3 ans plus tard pour être recertifiés avec un score de 105,2 points. B Corp est pour nous une immense fierté car ce n’est pas juste une certification, c’est une chance pour nous de montrer qu’un business peut agir pour le bien et de faire partie d’une communauté d’entreprises qui a la même mission.',
    ],
    buttonText: 'découvrir nos engagements BCorp',
    buttonAction: 'https://www.innocent.fr/a-propos-de-nous/bcorp',
  },
  {
    align: 'flex-end',
    isLight: true,
    bgColor: '#00573F',
    svgPath: 'spinach',
    title: 'innocent participe à la lutte contre le changement climatique',
    width: '600px',
    text: [
      'Comment ? En réduisant nos émissions partout où on le peut : dans notre chaîne d’approvisionnements, dans nos bureaux, dans notre usine, ou encore avec nos petites bouteilles. Dans cette course contre la montre pour le climat, notre usine, le Blender, joue un rôle clé car il fonctionne en grande partie aux énergies renouvelables. On a travaillé très dur pour y arriver et on est fiers d’avoir réussi à montrer qu’on peut construire une usine qui génère peu d’émissions carbone et où tout est fait pour limiter nos besoins énergétiques.',
    ],
    buttonText: 'découvrir nos engagements climat',
    buttonAction: 'https://www.innocent.fr/ce-que-nous-faisons-pour-la-planete/lutter-contre-le-changement-climatique',
  },
  {
    align: 'flex-start',
    isLight: true,
    bgColor: '#74AA50',
    title: 'soutenir nos agriculteurs',
    svgPath: 'apple',
    text: [
      "Étant donné que plus de la moitié de notre empreinte carbone provient de l'agriculture, nous savons que nous devons accentuer nos efforts dans ce domaine. On s'appuie sur l'agriculture régénérative pour réduire notre empreinte carbone. Avec nos fournisseurs, on investit dans des pratiques innovantes pour améliorer la santé des sols, la biodiversité et la résilience climatique tout au long de notre chaîne d'approvisionnement. On encourage des pratiques agricoles durables, la restauration et la revitalisation des terres agricoles où poussent les fruits et légumes contenus dans nos petites boissons.",
    ],
    buttonText: 'découvrir nos champions de l’agriculture',
    buttonAction: 'https://www.innocent.fr/ce-que-nous-faisons-pour-la-planete/nos-champions-de-lagriculture',
  },
  {
    align: 'flex-end',
    isLight: true,
    bgColor: '#00573F',
    svgPath: 'spinach',
    title: 'la grande classe verte',
    text: [
      "innocent a à cœur de soutenir des projets de transition agroécologique, qui visent à allier production agricole et préservation des ressources naturelles en accompagnant 5 projets inspirants au-delà de notre supply chain dans 5 régions de l’hexagone. C’est pour cela que pour la 3ème année consécutive innocent s’associe à MiiMOSA pour aider les agriculteurs à mieux résister au changement climatique en adoptant des pratiques d’agriculture respectueuses de l’environnement visant à protéger la santé des sols et la biodiversité mais aussi réduire la déforestation et promouvoir la gestion de l'eau.",
      "On croit dur comme fer que c'est tous ensemble qu'on pourra aller plus loin, et c'est pour ça qu'on invite tous ceux qui le souhaitent à donner un coup de pousse à la nature en soutenant des projets français qui prennent soin de la nature. Pour prendre soin de la nature comme elle prend soin de nous. Vous venez avec nous ?",
    ],
    buttonText: 'découvrir la grande classe verte',
    buttonAction: 'https://www.innocent.fr/lagrandeclasseverte',
  },
]

const Commitments = () => {
  return (
    <FullWidthContainer bgColor="bg.0" color="gray.200" position="relative" overflow="hidden">
      <Ornaments />
      <BoxedContainer
        px={{ base: 'none', md: '16' }}
        py={undefined}
        pt={{ base: '100px', md: 16 }}
        pb={{ base: '0px', md: 16 }}
      >
        <Box
          position="absolute"
          width="29px"
          display={{ base: 'block', md: 'none' }}
          top={{ base: '80px' }}
          height="29px"
          left={{ base: '0px' }}
          transform="rotate(-120deg) scale(1.5, 1.5)"
          zIndex="2"
        >
          <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
        </Box>

        <Flex position="relative" width={{ base: '100%', md: 'auto' }} mb="40px" alignSelf={commitmentsData[0].align}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '160px' }}
            height="29px"
            left={{ md: '-75px' }}
            transform="rotate(-50deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="78px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-45px' }}
            height="98px"
            right={{ md: '-20px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-white-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box display={{ base: 'block', md: 'none' }} position="absolute" top="-85px" left="110px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <Box display={{ base: 'none', md: 'block' }} zIndex="2" position="absolute" bottom="-20px" left="90px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <CommitmentBlock {...commitmentsData[1]} />
        </Flex>
        <Flex position="relative" mb="40px" alignSelf={{ base: 'auto', md: commitmentsData[1].align }}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-25px' }}
            height="29px"
            right={{ md: '200px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '40px' }}
            height="29px"
            left={{ md: '-100px' }}
            transform="rotate(-10deg)"
          >
            <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="61px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '100px' }}
            height="113px"
            left={{ md: '-50px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '20px' }}
            height="29px"
            left={{ md: '-50px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="64px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '-140px' }}
            height="89px"
            right={{ md: '120px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-mapple-leaf.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <CommitmentBlock {...commitmentsData[2]} />
        </Flex>
        <Flex position="relative" width={{ base: '100%', md: 'auto' }} mb="40px" alignSelf={commitmentsData[0].align}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '160px' }}
            height="29px"
            left={{ md: '-75px' }}
            transform="rotate(-50deg)"
          >
            <Image alt="innocent" src={s3ImageURL('red_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="78px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-45px' }}
            height="98px"
            right={{ md: '-20px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-white-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box display={{ base: 'block', md: 'none' }} position="absolute" top="-85px" left="110px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <Box display={{ base: 'none', md: 'block' }} zIndex="2" position="absolute" bottom="-20px" left="90px">
            <Image alt="innocent" src={s3ImageURL('innocent-falling-leave.svg')} width="82" height="70" />
          </Box>
          <CommitmentBlock {...commitmentsData[3]} />
        </Flex>
        <Flex position="relative" mb="40px" alignSelf={{ base: 'auto', md: commitmentsData[1].align }}>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '-25px' }}
            height="29px"
            right={{ md: '200px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="155px"
            top={{ base: '-100px', md: '80px' }}
            height="270px"
            right={{ base: '70px', md: '-115px' }}
          >
            <Image alt="innocent" src={s3ImageURL('innocent-panneau-bcorp.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '40px' }}
            height="29px"
            left={{ md: '-100px' }}
            transform="rotate(-10deg)"
          >
            <Image alt="innocent" src={s3ImageURL('orange_butterfly.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="61px"
            display={{ base: 'none', md: 'block' }}
            top={{ md: '100px' }}
            height="113px"
            left={{ md: '-50px' }}
            zIndex="1"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-dark-branch.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <Box
            position="absolute"
            width="29px"
            display={{ base: 'none', md: 'block' }}
            bottom={{ md: '20px' }}
            height="29px"
            left={{ md: '-50px' }}
            transform="rotate(-120deg)"
          >
            <Image alt="innocent" src={s3ImageURL('innocent-yellow-flower.svg')} fill style={{ objectFit: 'cover' }} />
          </Box>
          <CommitmentBlock {...commitmentsData[0]} />
        </Flex>
      </BoxedContainer>
    </FullWidthContainer>
  )
}

export default Commitments
