import { Container, Box, Heading, Text } from '@chakra-ui/react'
import Image from 'next/image'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'
import { Button } from '@miimosa/design-system/components'

const TwoYears = () => {
  return (
    <Container variant="full" position="relative" bgColor="#a0d884" mt="-3px">
      <Box zIndex="0" position="absolute" top={{ base: '0px', md: '0px' }} width="100%" height={'384px'}>
        <Image src={s3ImageURL(`xl-grey-bubble.svg`)} alt="" fill style={{ objectFit: 'cover' }} />
      </Box>
      <Container zIndex="1" variant="boxed" mb="20" position="relative">
        <Heading size="lg" mt="4" color="text" textAlign="center">
          3 ans de partenariat,
        </Heading>
        <Text size="md" mt="4" textAlign="center" px="1" maxW={{ md: '580px' }}>
          c&apos;est l&apos;âge de la grande classe verte. L&apos;opération menée par innocent qui accompagne des
          projets de revégétalisation, de préservation de la biodiversité et de transition agroécologique. Un petit coup
          de pousse pour la nature avec vous, plus nous, plus tous ceux qui le veulent.
        </Text>
        <NextLink passHref href="/projects">
          <Button mt="6" variant="secondary">
            voir tous les projets
          </Button>
        </NextLink>
      </Container>
    </Container>
  )
}

export default TwoYears
