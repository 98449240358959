import {
  Box,
  Flex,
  Grid,
  Heading,
  Input,
  Text,
  FormControl,
  FormErrorMessage,
  Container,
  useBreakpointValue,
} from '@chakra-ui/react'
import { FC, useState, useEffect } from 'react'

import { s3ImageURL } from '@miimosa/common'
import type { UserProposition, FrontSearchProjectType } from '@miimosa/common/types'
import { Image, Icon, Button } from '@miimosa/design-system'

import { ProjectCard } from '@components/ProjectCard'

interface Props {
  canVote: boolean
  projects: FrontSearchProjectType[]
  addVote: (slug: string) => Promise<void>
  getProjectAmount: (slug: string) => string
  votedSlug?: string
  isQRCode: boolean
}

const renderThankBloc = (
  setEmail: (v: string) => void,
  onSubscribeToProject: () => void,
  error: string | null,
  bgVotedImageFit?: 'contain' | 'cover'
) => (
  <Flex
    mt="6"
    px={{ base: 0, md: 18 }}
    py="12"
    width={{ base: '100%', md: '736px' }}
    direction="column"
    align="center"
    position="relative"
  >
    <Box position="absolute" width={{ base: '560px', md: '736px' }} height="100%" top={0} left="0" zIndex="0">
      <Image alt="" src={s3ImageURL('bulle-bg-white.svg')} fill style={{ objectFit: bgVotedImageFit }} />
    </Box>
    <Flex direction="column" width={{ base: '335px', md: '600px' }} align="center" zIndex="1">
      <Heading size="md" fontSize="24px" fontWeight="bold" color="gray.200">
        <Icon name="YoupiLeft" color="primary.DEFAULT" /> bravo <Icon name="YoupiRight" color="primary.DEFAULT" />
      </Heading>
      <Text size="lg" my="4" color="gray.200" textAlign="center">
        Grâce à votre vote innocent vient de reverser 20 centimes à ce projet
      </Text>
      <Text size="xs" color="gray.200" textAlign="center" maxWidth="560px">
        Vous souhaitez vous tenir au courant de la grande classe verte ? Laissez-nous votre mail nous vous enverrons des
        nouvelles, promis
      </Text>
      <Flex justify="space-between" direction={{ base: 'column', md: 'row' }} mt="4" align="center">
        <FormControl
          alignItems="center"
          width="auto"
          justifyContent="center"
          variant="floating"
          mt="6"
          isRequired
          isInvalid={!!error}
        >
          <Input
            placeholder="email@exemple.com"
            focusBorderColor="gray.200"
            color="gray.200"
            pb="10px"
            variant="flushed"
            width="290px"
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
        <Button variant="primary" onClick={onSubscribeToProject} size="md" mt={{ base: 4, md: 0 }}>
          s&apos;abonner
        </Button>
      </Flex>
    </Flex>
  </Flex>
)

const renderAlreadyVotedBloc = (bgVotedImageFit?: 'cover' | 'contain') => (
  <Flex width={{ base: '100%', md: '736px' }} align="center" justify="center" p="10" position="relative" mt="6">
    <Box position="absolute" width={{ base: '417px', md: '736px' }} height="100%" top={0} left="0" zIndex="0">
      <Image alt="" src={s3ImageURL('bulle-bg-white.svg')} fill style={{ objectFit: bgVotedImageFit }} />
    </Box>
    <Text py={{ base: '20px', md: '50px' }} size="md" textAlign="center" color="gray.200" zIndex="1">
      Vous avez déjà voté pour un projet.
      <br />
      Vous pourrez à nouveau <b>voter avec votre prochain achat de jus d’orange innocent.</b>
    </Text>
  </Flex>
)

const renderCannotVoteBloc = (bgVotedImageFit?: 'cover' | 'contain') => (
  <Flex width={{ base: '100%', md: '736px' }} align="center" justify="center" p="10" position="relative" mt="6">
    <Box position="absolute" width={{ base: '417px', md: '736px' }} height="100%" top={0} left="0" zIndex="0">
      <Image alt="" src={s3ImageURL('bulle-bg-white.svg')} fill style={{ objectFit: bgVotedImageFit }} />
    </Box>
    <Flex direction="column" py={{ base: '10px', md: '30px' }} px={{ base: 0, md: '40px' }}>
      <Text size="md" textAlign="center" color="gray.200" zIndex="1">
        Oups, vous ne pouvez pas voter.
      </Text>
      <Text size="lg" textAlign="center" color="gray.200" zIndex="1">
        Mais pas de panique, pour voter il vous suffit d’acheter un jus d’orange innocent (ceux avec la jolie étiquette)
        et flashez le QR code au dos.
      </Text>
    </Flex>
  </Flex>
)

const CurrentProjects: FC<Props> = ({ projects, addVote, votedSlug, isQRCode, getProjectAmount }) => {
  const [cantRevote, setCantRevote] = useState<boolean>(false)
  const [showThanks, setShowThanks] = useState<boolean>(false)
  const [cantVote, setCantVote] = useState<boolean>(false)
  const [tempSlug, setTemporarySlug] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [error, setError] = useState<string | null>(null)

  const handleSubscribe = async () => {
    setError(null)
    const res = await fetch(`/nextapi/projects/teasing`, {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        email,
        platform_country: 'innocent_fr',
        campaign_slug: 'innocent-users',
      } as UserProposition),
    })

    if (res.status === 200) {
      setShowThanks(false)
    } else {
      setError("Oups, une erreur s'est produite, veuillez réessayer")
    }
  }

  useEffect(() => {
    if (!votedSlug) {
      setCantRevote(false)
      setShowThanks(false)
    }
  }, [votedSlug])

  const handleCannotRevote = () => {
    setCantRevote(true)
    setShowThanks(false)
  }

  const handleVote = (slug: string) => {
    addVote(slug)
    setShowThanks(true)
    setCantRevote(false)
  }

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const bgVotedImageFit: undefined | 'cover' | 'contain' = useBreakpointValue(
    { base: 'cover', md: 'contain' },
    { fallback: 'md' }
  )

  return (
    <Container variant="full" bgColor="#74AA50" position="relative" pb={{ base: 12, md: 20 }} overflow="hidden">
      <Box display={{ base: 'none', md: 'block' }} position="absolute" right="-10px" top={{ md: '145px' }}>
        <Image src={s3ImageURL('innocent-light-branch.svg')} alt="" width="80" height="79" />
      </Box>
      <Box display={{ base: 'block', md: 'none' }} position="absolute" right="-10px" bottom={{ base: '20px' }}>
        <Image src={s3ImageURL('innocent-light-branch.svg')} alt="" width="80" height="79" />
      </Box>
      <Box display={{ base: 'block', md: 'none' }} position="absolute" right="-30px" top={{ base: '30%' }} zIndex="0">
        <Image src={s3ImageURL('innocent-dark-branch.svg')} alt="" width="80" height="79" />
      </Box>
      <Box position="absolute" left="-34px" top={{ base: '45%', md: '360px' }}>
        <Image src={s3ImageURL('innocent-white-flower.svg')} alt="" width="98" height="78" />
      </Box>
      <Container
        variant="boxed"
        justifyItems="center"
        py="undefined"
        px="undefined"
        pt={{ base: 12, md: 16 }}
        pb={{ base: 0, md: 0 }}
        position="relative"
      >
        <Box position="absolute" left={{ base: '10px', md: '200px' }} top={{ base: '2px', md: '10px' }}>
          <Image src={s3ImageURL('innocent-falling-leave-outline.svg')} alt="" width="50" height="43" />
        </Box>
        <Box position="absolute" display={{ base: 'none', md: 'block' }} right="320px" top="400px" zIndex="0">
          <Image src={s3ImageURL('innocent-dark-branch.svg')} alt="" width="80" height="79" />
        </Box>
        <Heading size="lg" color="white" mb="8">
          les 3 projets du moment
        </Heading>
        <Grid templateColumns={{ base: 'none', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap="16px">
          {projects.map((p, i) => {
            const isVoted = p.slug === votedSlug

            const handleClick = () => {
              if (!isQRCode) {
                setTemporarySlug(p.slug)
                return setCantVote(true)
              }

              return votedSlug ? handleCannotRevote() : handleVote(p.slug)
            }

            const buttonPath = isVoted ? 'voted' : 'vote'

            return (
              <Flex direction="column" align="center" key={`${i}_current_project_card`}>
                <Box position="relative" px={{ base: 5, md: 0 }} mb={{ base: '8', md: 'none' }}>
                  <ProjectCard showButton={false} project={p} key={i} />

                  <Flex align="flex-end" justify="flex-end" position="absolute" bottom="-32px" right="24px">
                    <Text mb="4px" fontSize="14px" fontWeight="bold" mr="2" color="white">
                      {getProjectAmount(p.slug)} €
                    </Text>

                    <Button variant="ghost" onClick={handleClick}>
                      <Image
                        width="64"
                        height="64"
                        alt="vote"
                        src={s3ImageURL(`innocent-${!isQRCode ? 'disabled' : buttonPath}-button.svg`)}
                      />
                    </Button>
                  </Flex>
                </Box>
                {isMobile &&
                  isVoted &&
                  showThanks &&
                  renderThankBloc(setEmail, handleSubscribe, error, bgVotedImageFit)}
                {cantRevote && isMobile && isVoted && renderAlreadyVotedBloc(bgVotedImageFit)}
                {cantVote && isMobile && tempSlug === p.slug && renderCannotVoteBloc(bgVotedImageFit)}
              </Flex>
            )
          })}
        </Grid>
        {showThanks && !isMobile && renderThankBloc(setEmail, handleSubscribe, error, bgVotedImageFit)}
        {cantRevote && !isMobile && renderAlreadyVotedBloc(bgVotedImageFit)}
        {cantVote && !isMobile && renderCannotVoteBloc(bgVotedImageFit)}
      </Container>
    </Container>
  )
}

export default CurrentProjects
