import { Flex, Box, Heading, Text, useBreakpointValue, Link } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system/components'

import Button from '@components/Button'

const CommitmentBlock = ({
  isLight,
  title,
  text,
  buttonText,
  width,
  buttonAction,
  legend,
  svgPath = 'spinach',
}: {
  isLight: boolean
  title: string
  text: string[]
  buttonText: string
  buttonAction: string
  width?: string
  svgPath: string
  legend?: string
}) => {
  const bgImageFit: 'cover' | 'contain' | undefined = useBreakpointValue(
    { base: 'cover', md: 'contain' },
    { fallback: 'md' }
  )
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Flex
      width={{ base: '100%', md: width || '636px' }}
      color={isLight ? 'bg.0' : 'gray.200'}
      direction="column"
      py={{ base: 12, md: 16 }}
      px={{ base: 5, md: '10' }}
      position="relative"
      alignItems="flex-start"
      justify="flex-start"
    >
      <Box position="absolute" width={{ base: '100%', md: width || '636px' }} height="100%" top={0} left="0" zIndex="0">
        <Image
          alt="innocent"
          src={s3ImageURL(`innocent-text-bloc-${svgPath}.svg`)}
          fill
          style={{ objectFit: bgImageFit }}
        />
      </Box>
      <Flex direction="column" alignItems="flex-start" justify="flex-start" zIndex="1">
        <Heading size="md" mb="4" px={{ base: 2, md: 0 }}>
          {title}
        </Heading>
        {text.map((t, i) => (
          <Text size="md" key={`text_${i}`} color={isLight ? 'white' : 'text'} mb="4" px={{ base: 2, md: 0 }}>
            {t}
          </Text>
        ))}
        {legend && (
          <Text size="xs" px={{ base: 2, md: 0 }}>
            {legend}
          </Text>
        )}
        <Flex justify="flex-start">
          <Button
            mt="4"
            minWidth="auto"
            variant={isLight ? 'secondaryW' : 'secondary'}
            color={isLight ? 'white' : 'black'}
            mx="auto"
            hidearrow={isMobile ? 'true' : undefined}
          >
            <Link isExternal href={buttonAction}>
              {buttonText}
            </Link>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CommitmentBlock
