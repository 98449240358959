import { Flex, Box, Heading, Text, BoxProps, Container, useBreakpointValue } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system/components'

const TextBlock = ({ first, second, third, ...props }: BoxProps & { first: string; second: string; third: string }) => (
  <Flex direction="column" textAlign="center" {...props}>
    <Text size={useBreakpointValue({ base: 'md', md: 'xl' }, { fallback: 'md' })} color="#212721">
      {first}
    </Text>
    <Text size={useBreakpointValue({ base: 'lg', md: '2xl' }, { fallback: 'md' })} color="#212721" mt="12px">
      {second}
      <br />
      <span style={{ color: '#E87722' }}>{third}</span>
    </Text>
  </Flex>
)

const HowItWorks = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const imgWidth = isMobile ? '360' : '108'
  const imgHeight = isMobile ? '180' : '108'
  const maxWidth = isMobile ? '100%' : '108px'

  return (
    <>
      <Container variant="full" bgColor="#fff">
        <Box display={{ base: 'block', md: 'none' }} pt={{ base: 4 }} mb={{ base: '4' }} maxWidth={maxWidth}>
          <Image
            src={s3ImageURL(`innocent-coin.${isMobile ? 'gif' : 'svg'}`)}
            alt=""
            width={imgWidth}
            height={imgHeight}
          />
        </Box>
        <Container variant="boxed" pt="2" position="relative">
          <Box position="absolute" top="60px" left="85px" zIndex="1" display={{ base: 'none', md: 'block' }}>
            <Image src={s3ImageURL(`coin-white.svg`)} alt="" width="108" height="108" />
          </Box>
          <Heading size="lg" color="gray.200" mb={{ base: 4, md: '12' }}>
            comment ça marche ?
          </Heading>
          <Flex
            direction="row"
            align="center"
            justify="center"
            wrap="wrap"
            mx={-6}
            my={-3}
            sx={{
              div: {
                mx: 6,
                my: 3,
                width: {
                  md: '470px',
                },
              },
            }}
          >
            <TextBlock
              first="Achetez un jus innocent (ceux avec la jolie étiquette) et flashez le QR code sur le côté de la bouteille."
              second="Regardez la vidéo du projet qui vous tient le plus à cœur,"
              third="on lui reverse 1 euro."
            />
          </Flex>
          {/* <Button variant="secondary" size="md" as="a" href="/projects" mt="6">
            voir tous les projets
          </Button> */}
        </Container>
      </Container>
      <Container variant="full" bgColor="#95c34d" height="32px">
        <Box width="100%" height="32px" position="relative">
          <Image src={s3ImageURL('innocent-how-it-works-bg.svg')} fill alt="" />
        </Box>
      </Container>
    </>
  )
}

export default HowItWorks
